/* @import must be at top of file, otherwise CSS will not work */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h3 {
  font-weight: 600;
  color: #292f31;
}

button {
  cursor: pointer;
}

p {
  line-height: 1.5em;
}

input {
  font-family: inherit;
}
